const CallToAction = () => {
  return (
    <section className="max-w-[1920px] mx-auto py-16 xl:py-24 flex flex-col items-center space-y-6 md:space-y-8 xl:space-y-12">
      <img
        src="https://ittybit-app.ittybitcdn.com/med_3b5mNn40OfFdDkNz/original"
        className="w-[240px] lg:w-[320px] h-auto mx-auto"
        width={320}
        height={336}
        alt="Pixel art of a coin entering a slot"
      />
      <div className="max-w-screen-xl mx-auto px-6 xl:px-0 space-y-6">
        <h2 className="text-heading-1 font-pixel text-1 text-center uppercase">
          Insert Coin(s) to Continue
        </h2>
        <div className="flex flex-col lg:flex-row items-center justify-center max-w-xs lg:max-w-[800px] mx-auto space-y-6 lg:space-y-0 lg:space-x-6">
          <a
            className="block w-full px-6 py-4 text-lg text-1 text-center bg-dark-400 hover:bg-dark-500 rounded-sm"
            href="mailto:p@ittybit.com"
          >
            Email p@ittybit.com
          </a>
          <a
            className="block w-full px-6 py-4 text-lg text-1 text-center bg-brand-500 hover:bg-brand-300 rounded-sm"
            href="https://savvycal.com/paulantwilliams/ittybit-preseed"
          >
            Schedule 25min Chat
          </a>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
