const Icon = ({ icon, size = "md", className = "", hoverText }) => {
  let textSize = "text-[24px]";
  if (size === "2xs") textSize = "text-[12px]";
  if (size === "xs") textSize = "text-[16px]";
  if (size === "sm") textSize = "text-[20px]";
  if (size === "md") textSize = "text-[24px]";
  if (size === "lg") textSize = "text-[32px]";
  if (size === "xl") textSize = "text-[48px]";
  if (size === "2xl") textSize = "text-[64px]";
  if (size === "3xl") textSize = "text-[96px]";
  if (size === "4xl") textSize = "text-[128px]";
  if (size === "5xl") textSize = "text-[160px]";
  return (
    <span
      title={hoverText}
      className={`font-icon ${textSize} leading-none lowercase ${className}`}
    >
      {icon}
    </span>
  );
};
export default Icon;
