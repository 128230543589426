"use client";

import { Picture } from "components/utilities";
import Link from "next/link";
import { useSearchParams } from "next/navigation";

export default function Navigation() {
  const params = useSearchParams();
  const email = params.get("email") || "";

  const baseLink = `/?email=${email}`;

  const links = [
    {
      label: "Overview",
      href: `${baseLink}#overview`,
    },
    {
      label: "Problem",
      href: `${baseLink}#problem`,
    },
    {
      label: "Product",
      href: `${baseLink}#product`,
    },
    {
      label: "Team",
      href: `${baseLink}#team`,
    },
    {
      label: "Model",
      href: `${baseLink}#model`,
    },
    {
      label: "GTM",
      href: `${baseLink}#gtm`,
    },
    {
      label: "Traction",
      href: `${baseLink}#traction`,
    },
    {
      label: "Raise",
      href: `${baseLink}#raise`,
    },
    {
      label: "FAQ",
      href: `/faq?email=${email}`,
    },
  ];

  return (
    <nav className="app-navigation z-50 isolate bg-violet-800 dark:bg-dark-400 text-1 sticky top-0 left-0 right-0 px-6">
      <div className="max-w-screen-2xl mx-auto flex items-center justify-center lg:justify-start transition-opacity">
        <a
          href={`/?email=${email}`}
          className="flex items-center space-x-2 px-4 md:px-8 xl:pl-0 pt-3 lg:py-3 opacity-100 hover:opacity-60 flex-shrink-0"
        >
          <Picture
            src="https://ittybit-app.ittybitcdn.com/med_rbcpu5q6hdrp/original"
            width="160"
            height="40"
            sizes="96px"
            className="h-6 w-24 object-contain"
            alt="ittybit"
            rendering="pixelated"
            priority
          />
          <span className="font-pixel text-brand-300 text-heading-5">
            /invest
          </span>
        </a>
        <div className="hidden lg:flex ml-auto items-center justify-start overflow-x-auto">
          {links.map((link) => (
            <Link
              key={link.label}
              href={`${link.href}`}
              className="block px-6 rounded-sm font-medium text-sm leading-12 text-3 hover:text-1"
            >
              {link.label}
            </Link>
          ))}
        </div>
      </div>

      <div className="lg:hidden max-w-screen-2xl mx-auto flex items-center justify-start overflow-x-auto">
        {links.map((link) => (
          <Link
            key={link.href}
            href={`${link.href}`}
            className="block px-6 py-3 rounded-sm font-medium text-sm leading-12 text-3 hover:text-1"
          >
            {link.label}
          </Link>
        ))}
      </div>
    </nav>
  );
}
